import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";
import FileUtils from "../../utils/fileUtils";

const fileToBase64AndURL = new FileUtils();

class DynamicCustomFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFieldType: "",     // Tipo de campo seleccionado (Pregunta, URL, Banner)
      selectedQuestionType: "",  // Tipo de pregunta seleccionado (Texto, Número, Desplegable, etc.)
      fieldsData: [],            // Arreglo con todos los campos agregados
    };
  }

  // Actualiza el componente padre con la lista de campos agregados
  updateParentFields = () => {
    if (this.props.onFieldsChange) {
      this.props.onFieldsChange(this.state.fieldsData);
    }
  };

  // Maneja el cambio del tipo de campo (Pregunta, URL, Banner)
  handleFieldTypeChange = (e) => {
    this.setState({ selectedFieldType: e.target.value });
  };

  // Añade un nuevo campo según el tipo seleccionado
  handleAddField = () => {
    const { selectedFieldType, selectedQuestionType } = this.state;
    if (selectedFieldType) {
      const isQuestion = selectedFieldType === "Pregunta";
      const newField = {
        type: selectedFieldType,
        name: "",
        questionType: isQuestion ? selectedQuestionType : "",
        // Si el tipo de pregunta es Desplegable o Multiple Choice, inicializa con un arreglo con un item vacío
        // Si es Pregunta con otro tipo (Texto, Número), inicializa con un arreglo vacío
        // Si no es Pregunta (ej: URL, Banner), no necesita options
        options:
          isQuestion &&
          (selectedQuestionType === "Desplegable" ||
            selectedQuestionType === "Multiple Choice")
            ? [""]
            : isQuestion
            ? []
            : undefined,
        
        // Para el banner luego agregaremos 'file' y 'base64' cuando se cargue un archivo
      };

      this.setState(
        (prevState) => ({
          fieldsData: [...prevState.fieldsData, newField],
          selectedFieldType: "",
          selectedQuestionType: "",
        }),
        this.updateParentFields
      );
    }
  };

  // Actualiza el nombre de la pregunta o de la URL en el campo correspondiente
  handleFieldNameChange = (e, index) => {
    const { value } = e.target;
    const fieldsData = [...this.state.fieldsData];
    fieldsData[index].name = value;
    this.setState({ fieldsData }, this.updateParentFields);
  };

  // Maneja el cambio en las opciones para campos Desplegables o Multiple Choice
  handleOptionChange = (e, fieldIndex, optionIndex) => {
    const { value } = e.target;
    const fieldsData = [...this.state.fieldsData];
    fieldsData[fieldIndex].options[optionIndex] = value;
    this.setState({ fieldsData }, this.updateParentFields);
  };

  // Agrega una nueva opción vacía al campo Desplegable o Multiple Choice
  handleAddOption = (fieldIndex) => {
    const fieldsData = [...this.state.fieldsData];
    if (!fieldsData[fieldIndex].options) {
      fieldsData[fieldIndex].options = [];
    }
    fieldsData[fieldIndex].options.push("");
    this.setState({ fieldsData }, this.updateParentFields);
  };

  // Elimina una opción específica de un campo Desplegable o Multiple Choice
  handleRemoveOption = (fieldIndex, optionIndex) => {
    const fieldsData = [...this.state.fieldsData];
    fieldsData[fieldIndex].options.splice(optionIndex, 1);
    this.setState({ fieldsData }, this.updateParentFields);
  };

  // Maneja la selección del archivo para el banner, validando el tamaño
  handleBannerChange(e, index) {
    const file = e.target.files[0];
    fileToBase64AndURL.fileToBase64AndURL(file)
      .then(({ fileURL, base64 }) => {
        const fieldsData = [...this.state.fieldsData];
        fieldsData[index].file = fileURL;
        fieldsData[index].base64 = base64;
        this.setState({ fieldsData }, this.updateParentFields);
      })
      .catch((err) => alert(err));
  }

  // Cambia el tipo de pregunta (Texto, Número, Desplegable, Multiple Choice)
  handleQuestionTypeChange = (e, index) => {
    const { value } = e.target;
    const fieldsData = [...this.state.fieldsData];
    fieldsData[index].questionType = value;

    // Si el tipo de pregunta requiere opciones y no existen, se inicializan
    if (
      (value === "Desplegable" || value === "Multiple Choice") &&
      (!fieldsData[index].options || fieldsData[index].options.length === 0)
    ) {
      fieldsData[index].options = [""];
    }

    this.setState({ fieldsData }, this.updateParentFields);
  };

  // Elimina un campo del listado
  handleDeleteField = (index) => {
    const fieldsData = [...this.state.fieldsData];
    fieldsData.splice(index, 1);
    this.setState({ fieldsData }, this.updateParentFields);
  };

  render() {
    const { selectedFieldType, selectedQuestionType, fieldsData } = this.state;

    return (
      <>
        <div className="form-row">
          {/* Selección del tipo de campo: Pregunta, URL o Banner */}
          <div className="form-group col-md-6">
            <label htmlFor="field_type" style={{ fontWeight: "bold" }}>
              Tipo de Campo
            </label>
            <select
              id="field_type"
              className="form-control"
              value={selectedFieldType}
              onChange={this.handleFieldTypeChange}
            >
              <option value="">-- Seleccionar Tipo de Campo --</option>
              <option value="Pregunta">Pregunta</option>
              <option value="URL">URL</option>
              <option value="Banner">Banner</option>
            </select>
          </div>

          {/* Si el campo es Pregunta, permite seleccionar el tipo de pregunta */}
          {selectedFieldType === "Pregunta" && (
            <div className="form-group col-md-6">
              <label htmlFor="question_type" style={{ fontWeight: "bold" }}>
                Tipo de Pregunta
              </label>
              <select
                id="question_type"
                className="form-control"
                value={selectedQuestionType}
                onChange={(e) =>
                  this.setState({ selectedQuestionType: e.target.value })
                }
              >
                <option value="">-- Seleccionar Tipo de Pregunta --</option>
                <option value="Texto">Texto</option>
                <option value="Número">Número</option>
                <option value="Desplegable">Desplegable</option>
                <option value="Multiple Choice">Multiple Choice</option>
              </select>
            </div>
          )}

          {/* Botón para agregar el campo seleccionado */}
          <div className="form-group col-md-7 d-flex align-items-end">
            <Tooltip title="Añadir campo para completar" placement="right" arrow>
              <button
                type="button"
                className="btn btn-success"
                onClick={this.handleAddField}
              >
                <FontAwesomeIcon icon={faPlus} /> Añadir campo
              </button>
            </Tooltip>
          </div>
        </div>

        {/* Si hay campos agregados, mostrarlos en una lista */}
        {fieldsData.length > 0 && (
          <div className="col-md-12 mt-4 mb-2">
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h6 className="mb-0">Editar Campos</h6>
                <FontAwesomeIcon icon={faEdit} style={{ color: "blue" }} />
              </div>
              <ul className="list-group list-group-flush">
                {fieldsData.map((field, index) => (
                  <li key={index} className="list-group-item">
                    <div
                      className="d-flex justify-content-between align-items-start"
                      style={{ position: "relative" }}
                    >
                      <div style={{ width: "100%" }}>
                        <strong>Tipo:</strong> {field.type}

                        {/* Si el campo es de tipo Pregunta, mostrar inputs según el tipo de pregunta */}
                        {field.type === "Pregunta" && (
                          <div className="mt-2">
                            {/* Inputs para el texto de la pregunta y el tipo de pregunta */}
                            <div className="row">
                              <div className="form-group col-md-6 mt-1">
                                <strong>Pregunta:</strong>
                                <input
                                  type="text"
                                  className="form-control mt-1"
                                  placeholder="Ingrese su pregunta"
                                  value={field.name}
                                  onChange={(e) =>
                                    this.handleFieldNameChange(e, index)
                                  }
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor={`tipoPregunta-${index}`}>
                                  <strong>Tipo de Pregunta</strong>
                                </label>
                                <select
                                  id={`tipoPregunta-${index}`}
                                  className="form-control"
                                  value={field.questionType}
                                  onChange={(e) =>
                                    this.handleQuestionTypeChange(e, index)
                                  }
                                >
                                  <option value="">Selecciona una opción</option>
                                  <option value="Texto">Texto</option>
                                  <option value="Número">Número</option>
                                  <option value="Desplegable">Desplegable</option>
                                  <option value="Multiple Choice">Multiple Choice</option>
                                </select>
                              </div>
                            </div>

                            {/* Opciones para Desplegable o Multiple Choice */}
                            {(field.questionType === "Desplegable" ||
                              field.questionType === "Multiple Choice") && (
                              <div className="form-group mt-3">
                                <label><strong>Opciones</strong></label>
                                <div className="row">
                                  {field.options.map((option, idx) => (
                                    <div
                                      key={idx}
                                      className="col-md-3 d-flex align-items-center mb-2"
                                    >
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={option}
                                        onChange={(e) =>
                                          this.handleOptionChange(e, index, idx)
                                        }
                                      />
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-sm ml-2"
                                        onClick={() =>
                                          this.handleRemoveOption(index, idx)
                                        }
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </button>
                                    </div>
                                  ))}
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-outline-primary mt-2"
                                  onClick={() => this.handleAddOption(index)}
                                >
                                  Añadir Opción
                                </button>

                                {/* Vista previa de las opciones según el tipo de pregunta */}
                                <div className="mt-3">
                                  <label><strong>Vista Previa:</strong></label>
                                  {field.questionType === "Desplegable" && (
                                    <select className="form-control mt-2">
                                      {field.options.map((opt, i) => (
                                        <option key={i} value={opt}>
                                          {opt}
                                        </option>
                                      ))}
                                    </select>
                                  )}

                                  {field.questionType === "Multiple Choice" && (
                                    <div className="mt-2">
                                      {field.options.map((opt, i) => (
                                        <div className="form-check" key={i}>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`option-${index}-${i}`}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={`option-${index}-${i}`}
                                          >
                                            {opt}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {/* Si el campo es de tipo URL, mostrar el input para URL */}
                        {field.type === "URL" && (
                          <div className="mt-2">
                            <label>Enlace web:</label>
                            <input
                              type="url"
                              className="form-control"
                              placeholder="Ingrese el enlace completo"
                              value={field.name}
                              onChange={(e) => this.handleFieldNameChange(e, index)}
                            />
                          </div>
                        )}

                        {/* Si el campo es de tipo Banner, mostrar el input file */}
                        {field.type === "Banner" && (
                          <div className="mt-2">
                            <label>Banner:</label>
                            <div className="form-row align-items-center">
                              <div className="form-group col-md-6">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i
                                        className="fas fa-image"
                                        style={{ fontSize: "1.5em" }}
                                      ></i>
                                    </span>
                                  </div>
                                  <input
                                    type="file"
                                    className="form-control"
                                    onChange={(e) =>
                                      this.handleBannerChange(e, index)
                                    }
                                  />
                                </div>
                              </div>

                              {/* NUEVO: Vista previa a la derecha si existe banner_url */}
                              {field.file && (
                                <div className="form-group col-md-2 d-flex align-items-center ml-2">
                                  <img
                                    src={field.file}
                                    alt="Banner Preview"
                                    style={{
                                      width: "100%",
                                      height: "auto",
                                      border: "1px solid #ccc",
                                      borderRadius: "4px",
                                      padding: "4px",
                                      backgroundColor: "#f9f9f9"
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>

                      {/* Botón para eliminar el campo */}
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => this.handleDeleteField(index)}
                        type="button"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default DynamicCustomFields;

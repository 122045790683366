class FileUtils {
  fileToBase64AndURL(file, maxSizeMB = 10) {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject("No file provided");
        return;
      }

      const size = file.size / 1024 / 1024; // in MB
      if (size > maxSizeMB) {
        reject("Archivo demasiado grande.");
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const fileURL = URL.createObjectURL(file); // Vista previa del archivo
        const base64 = reader.result; // Cadena base64 del archivo
        resolve({ fileURL, base64 });
      };
      reader.onerror = () => {
        reject("Error leyendo el archivo.");
      };
    });
  }
}

export default FileUtils;

import React, { Component } from 'react';
import axios from 'axios';
import MuiAlert from '@material-ui/lab/Alert';
import { trackPromise } from 'react-promise-tracker';
import { addMonths, format } from 'date-fns';
import DaysOfMonth from '../utils/daysOfMonth';
import CustomFieldsUtils from '../utils/customFieldsUtils';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class CrearCliente extends Component {

    constructor() {
        super()
        this.state = {
            activeStep: 0,
            maxSteps: 2,
            name: '',
            lastName: '',
            identificationType: '',
            identification: '',
            sex: 'M',
            bornDate: '',
            nationality: null,
            street: '',
            number: '',
            addressComments: '',
            country: null,
            province: null,
            locality: null,
            postalCode: '',
            email: '',
            phone: '',
            facebook: '',
            cities: null,
            countries: null,
            provinces: null,
            open: false,
            exists: false,
            born_day: null,
            born_month: null,
            born_year: null,
            acceptTermsAndConditions: false,
            clientCustomFields: [],
            customTextInput: {},
        }

        this.onConfirmButtonClicked = this.onConfirmButtonClicked.bind(this)
        
        this.onIdentificationTypeChanged = this.onIdentificationTypeChanged.bind(this)
        this.onIdentificationChanged = this.onIdentificationChanged.bind(this)
        this.onNameChanged = this.onNameChanged.bind(this)
        this.onLastNameChanged = this.onLastNameChanged.bind(this)
        this.onSexChanged = this.onSexChanged.bind(this)
        this.onNationalityChanged = this.onNationalityChanged.bind(this)
        this.onBornDateChanged = this.onBornDateChanged.bind(this)
        this.onStreetChanged = this.onStreetChanged.bind(this)
        this.onNumberChanged = this.onNumberChanged.bind(this)
        this.onAddressCommentsChanged = this.onAddressCommentsChanged.bind(this)
        this.onCountryChanged = this.onCountryChanged.bind(this)
        this.onProvinceChanged = this.onProvinceChanged.bind(this)
        this.onLocalityChanged = this.onLocalityChanged.bind(this)
        this.onPostalCodeChanged = this.onPostalCodeChanged.bind(this)
        this.onEmailChanged = this.onEmailChanged.bind(this)
        this.onPhoneChanged = this.onPhoneChanged.bind(this)

        this.onBornDayChanged = this.onBornDayChanged.bind(this)
        this.onBornMonthChanged = this.onBornMonthChanged.bind(this)
        this.onBornYearChanged = this.onBornYearChanged.bind(this)

        this.onFacebookChanged = this.onFacebookChanged.bind(this)
        this.onKeyUp = this.onKeyUp.bind(this)
        this.onFocusOut = this.onFocusOut.bind(this)
        this.resetState = this.resetState.bind(this)
        this.handleTermsAndConditionsCheckboxChange = this.handleTermsAndConditionsCheckboxChange.bind(this)
        this.isOptionChecked = this.isOptionChecked.bind(this)
        this.manageCustomFieldChange = this.manageCustomFieldChange.bind(this)
    }

    resetState() {
        this.setState({
            activeStep: 0,
            name: '',
            lastName: '',
            identificationType: '',
            identification: '',
            sex: '',
            bornDate: '',
            street: '',
            number: '',
            addressComments: '',
            country: null,
            province: null,
            locality: null,
            postalCode: '',
            email: '',
            phone: '',
            facebook: '',
            cities: null,
            provinces: null,
            open: false,
            exists: false,
            born_day: null,
            born_month: null,
            born_year: null,
            acceptTermsAndConditions: false,
            clientCustomFields: [],
            customTextInput: {},
        })
    }

    componentDidMount() {
        this.getCountries();
        //count max steps
        if (this.props.requestAddress === 1) {
            this.setState({
                maxSteps: this.state.maxSteps + 1 
            })
        }
        if (this.props.customFields !== null) {
            this.setState({
                maxSteps: this.state.maxSteps + 1 
            })
        }
    }

    onConfirmButtonClicked(e) {
        e.preventDefault();
        let storeId = parseInt(this.props.storeID);

        let nationality = null
        if (this.state.nationality != null) {
            nationality = parseInt(this.state.nationalityy)
        }

        let country = null
        if (this.state.country != null) {
            country = parseInt(this.state.country)
        }

        let locality = null
        if (this.state.locality != null) {
            locality = parseInt(this.state.locality)
        }

        let province = null
        if (this.state.province != null) {
            province = parseInt(this.state.province)
        }

        if (this.state.born_day != null && this.state.born_day != "NULL" &&
            this.state.born_month != null && this.state.born_month != "NULL" && 
            this.state.born_year != null && this.state.born_year != "NULL") {

                const obj = {
                    name: this.state.name,
                    last_name: this.state.lastName,
                    identification: this.state.identification,
                    identification_type: this.state.identificationType,
                    sex: this.state.sex,
                    email: this.state.email,
                    phone: this.state.phone,
                    facebook: this.state.facebook,
                    street: this.state.street,
                    number: this.state.number,
                    comments: this.state.addressComments,
                    nationality_id: nationality,
                    country_id: country,
                    province_id: province,
                    locality_id: locality,
                    postal_code: this.state.postalCode,
                    born_date: this.state.born_year + "-" + this.state.born_month + "-" + this.state.born_day,
                    store_id: storeId,
                };

                if (!this.state.acceptTermsAndConditions) {
                    alert('Debes aceptar los términos y condiciones para confirmar el formulario.');
                    return
                }

                var url = `${this.props.hubURL}/stores/${storeId}/clients`;
                let clientID;

                trackPromise(
                    axios.post(url, obj, { headers: { 'Access-Control-Allow-Origin': '*' } })
                        .then(async (res) => {
                            if (res.data.code === 200) {
                                clientID = res.data.user_id;
                                var createClientCustomFieldsURL = `${this.props.hubURL}/stores/${storeId}/clients/${clientID}/custom_fields`;
                                // Second Axios call for custom fields
                                try {
                                    const res_1 = await axios.post(createClientCustomFieldsURL, this.state.clientCustomFields, { headers: { 'Access-Control-Allow-Origin': '*' } });
                                    if (res_1.data.code === 200) {
                                        this.setState({ open: true });
                                    } else {
                                        throw new Error("Ups! Ocurrió un error al crear campos personalizados. Intenta nuevamente.");
                                    }
                                } catch (error) {
                                    console.error(error);
                                    // Handle error for the second call
                                    alert("Ups! Ocurrió un error. Intenta nuevamente. crear custom fields");
                                }
                            } else if (res.data.code === 1000) {
                                alert("Ups! El usuario ya existe.");
                            } else {
                                throw new Error("Ups! Ocurrió un error al crear el cliente. Intenta nuevamente.");
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            // Handle error for the first call
                            alert("Ups! Ocurrió un error. Intenta nuevamente. crear user");
                        })
                );
        } else {
            alert("Ups! Ocurrió un error. Intenta nuevamente.")
        }
    }

    onIdentificationTypeChanged(e) { this.setState({identificationType: e.target.value}) }
    onIdentificationChanged(e) { 
        this.setState({identification: e.target.value}) 
    }

    onKeyUp(e) {
        if (e.key === "Enter") {
            this.lookupSubscription(this.state.identification);
        }
    }

    onFocusOut() {
        if (this.state.identification !== '') {
            this.lookupSubscription(this.state.identification);
        }
    }

    lookupSubscription(identification) {
        let storeId = parseInt(this.props.storeID);
        let lookup = `${this.props.hubURL}/stores/${storeId}/clients/${identification}`
        
        trackPromise(
            axios.get(lookup, { headers: { 'Access-Control-Allow-Origin': '*',} })
                .then(res => { 
                    if (res.data["name"] !== null) {
                        alert("Ya existe un cliente registrado con estos datos en esta marca.");
                        this.resetState();
                    } else {
                        this.lookupUser(identification);
                    }
                })
                .catch(error => {
                    console.log(error); 
                    this.lookupUser(identification);
                })
        );  
    }

    lookupUser(identification) {
        let lookup = `${this.props.hubURL}/users/lookup/${identification}`
        
        trackPromise(
            axios.get(lookup, { headers: { 'Access-Control-Allow-Origin': '*',} })
                .then(res => { 
                    if (res.data["name"] !== null) {

                        var day = ""
                        var month = ""
                        var year = ""

                        if (res.data.born_date.includes('-')) {
                            let parts = res.data.born_date.split('-');
                            year = parts[0]
                            month = parts[1]
                            day = parts[2]
                        } else {
                            let parts = res.data.born_date.split('/');
                            year = parts[2]
                            month = parts[1]
                            day = parts[0]
                        }

                        this.setState({ 
                            name: res.data.name,
                            lastName: res.data.last_name,
                            identification: res.data.identification,
                            sex: res.data.sex,
                            email: res.data.email,
                            phone: res.data.phone,
                            born_day: day,
                            born_month: month,
                            born_year: year,
                            exists: true,
                        });
                    } else {
                        this.setState({identification: identification, exists: false}) 
                    }
                })
                .catch(error => {
                    console.log(error); 
                    this.setState({identification: identification, exists: false});
                })
        );  
    }

    onNameChanged(e) { this.setState({name: e.target.value}) }
    onLastNameChanged(e) { this.setState({lastName: e.target.value}) }
    onSexChanged(e) { this.setState({sex: e.target.value}) }

    onBornDayChanged(e) { this.setState({born_day: e.target.value}) }
    onBornMonthChanged(e) { this.setState({born_month: e.target.value}) }
    onBornYearChanged(e) { this.setState({born_year: e.target.value}) }

    onBornDateChanged(e) { this.setState({bornDate: e}) }
    onStreetChanged(e) { this.setState({street: e.target.value}) }
    onNumberChanged(e) { this.setState({number: e.target.value}) }
    onAddressCommentsChanged(e) { this.setState({addressComments: e.target.value}) }

    isOptionChecked(fieldName, option) {
        const selectedOptions = this.state.clientCustomFields.find(
            (customField) => customField.name === fieldName
        );
    
        if (selectedOptions) {
            const selectedOptionsArray = selectedOptions.multiple_choice_field.split(',');
            return selectedOptionsArray.includes(option);
        }
    
        return false;
    }     

    manageCustomFieldChange(e, field, option) {
        const customFieldUtils = new CustomFieldsUtils();
    
        if (field.type === customFieldUtils.CustomFieldTypes.TEXT) {
            this.setState({
                [field.name]: e.target.value,
            });
        } else if (field.type === customFieldUtils.CustomFieldTypes.DROPDOWN) {
            const existingIndex = this.state.clientCustomFields.findIndex(
                (customField) => customField.name === field.name
            );
    
            const newClientCustomField = {
                name: field.name,
                type: field.type,
                multiple_choice_field: e.target.value,
            };
    
            this.setState((prevState) => {
                let updatedClientCustomFields;
    
                if (existingIndex !== -1) {
                    // Si ya existe, reemplaza la opción en el mismo índice
                    updatedClientCustomFields = [...prevState.clientCustomFields];
                    updatedClientCustomFields[existingIndex] = newClientCustomField;
                } else {
                    // Si no existe, agrega la nueva opción
                    updatedClientCustomFields = [...prevState.clientCustomFields, newClientCustomField];
                }
    
                return {
                    ...prevState,
                    clientCustomFields: updatedClientCustomFields,
                };
            });
        } else if (field.type === customFieldUtils.CustomFieldTypes.MULTIPLE_CHOICE) {
            const isChecked = e.target.checked;
    
            this.setState((prevState) => {
                let updatedClientCustomFields;
    
                if (isChecked) {
                    // Si está marcado, añade la opción por comas si ya existe, de lo contrario, crea una nueva
                    const existingIndex = prevState.clientCustomFields.findIndex(
                        (customField) => customField.name === field.name
                    );
    
                    if (existingIndex !== -1) {
                        // Si ya existe, agrega la opción al valor existente por comas
                        const existingField = prevState.clientCustomFields[existingIndex];
                        const updatedOptions = existingField.multiple_choice_field
                            ? `${existingField.multiple_choice_field},${option}`
                            : option;
    
                        updatedClientCustomFields = [...prevState.clientCustomFields];
                        updatedClientCustomFields[existingIndex] = {
                            name: field.name,
                            type: field.type,
                            multiple_choice_field: updatedOptions,
                        };
                    } else {
                        // Si no existe, crea una nueva opción
                        updatedClientCustomFields = [
                            ...prevState.clientCustomFields,
                            {
                                name: field.name,
                                type: field.type,
                                multiple_choice_field: option,
                            },
                        ];
                    }
                } else {
                    // Si no está marcado, elimina la opción del campo multiple_choice_field
                    const existingIndex = prevState.clientCustomFields.findIndex(
                        (customField) => customField.name === field.name
                    );
    
                    if (existingIndex !== -1) {
                        const existingField = prevState.clientCustomFields[existingIndex];
                        const remainingOptions = existingField.multiple_choice_field
                            .split(',')
                            .filter((o) => o !== option)
                            .join(',');
    
                        updatedClientCustomFields = [...prevState.clientCustomFields];
                        if (remainingOptions) {
                            updatedClientCustomFields[existingIndex] = {
                                name: field.name,
                                type: field.type,
                                multiple_choice_field: remainingOptions,
                            };
                        } else {
                            // Si no quedan opciones, elimina el campo customField
                            updatedClientCustomFields.splice(existingIndex, 1);
                        }
                    } else {
                        // Si no existe, no hay nada que hacer
                        updatedClientCustomFields = [...prevState.clientCustomFields];
                    }
                }
                
                return {
                    ...prevState,
                    clientCustomFields: updatedClientCustomFields,
                };
            });
        }
    }

    onChangeCustomFieldText(e, field) {
        e.preventDefault();
        const newClientCustomField = {
            name: field.name,
            type: field.type,
            text_field: this.state[field.name] ? this.state[field.name] : '',
        }
        this.setState((prevState) => ({
            clientCustomFields: [...prevState.clientCustomFields, newClientCustomField]
        }))
    }


    onNationalityChanged(e) { 
        if (e.target.value != "-1") {
            this.setState({nationality: e.target.value})
        }
    }
    onCountryChanged(e) { 
        if (e.target.value != "-1") {
            this.getProvinces(e.target.value)
        }
    }
    onProvinceChanged(e) { 
        if (e.target.value != "-1") {
            this.getCities(e.target.value)
        }
    }
    onLocalityChanged(e) { this.setState({locality: e.target.value}) }
    onPostalCodeChanged(e) { this.setState({postalCode: e.target.value}) }
    onEmailChanged(e) { this.setState({email: e.target.value}) }
    onPhoneChanged(e) { this.setState({phone: e.target.value}) }
    onFacebookChanged(e) { this.setState({facebook: e.target.value}) }

    getCountries() {
        var url = `${this.props.hubURL}/countries`;
        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*' }})
                .then(res => { this.setState({countries: res.data }) })
                .catch(function (error) { 
                    console.log(error);
                })
        );
    }

    getProvinces(countryID) {
        var url = `${this.props.hubURL}/countries/${countryID}/provincies`;
        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*' }})
                .then(res => { this.setState({country: countryID, provinces: res.data }) })
                .catch(function (error) { console.log(error); })
        );
    }

    getCities(provinceID) {
        if (this.state.country != null) {
            var url = `${this.props.hubURL}/countries/${this.state.country}/provincies/${provinceID}/cities`;
            trackPromise(
                axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*' }})
                    .then(res => { this.setState({province: provinceID, cities: res.data }) })
                    .catch(function (error) { console.log(error); })
            );
        }
    }

    handleTermsAndConditionsCheckboxChange = () => {
        this.setState((prevState) => ({
          acceptTermsAndConditions: !prevState.acceptTermsAndConditions,
        }));
      };

    nextStep(current) {
        this.setState({activeStep: Math.min(this.state.maxSteps, Number(current+1))})
    }

    prevStep(current) {
        console.log(Math.max(0, Number(current-1)))
        this.setState({activeStep: Math.max(0, Number(current-1))})
    }

    render() {
        const customFieldUtils = new CustomFieldsUtils()
        if (this.state.open) {
            alert("Cliente creado correctamente!");
            this.resetState();
        }

        let readonly = "false"
        if (this.state.exists) {
            readonly = "true"
        }

        let minDate = format(addMonths(new Date(), -1200), "yyyy-MM-dd")
        let maxDate = format(new Date(), "yyyy-MM-dd")

        let tabinactive = "nav-link"
        let tabactive = "nav-link active"

        let contentinactive = "tab-pane fade"
        let contentactive = "tab-pane fade show active"

        let personaldataclass = tabinactive
        let addressclass = tabinactive
        let customFieldsClass = tabinactive
        let communicationclass = tabinactive

        let personaldatacontentclass = contentinactive
        let addresscontentclass = contentinactive
        let customFieldsContentClass = contentinactive
        let communicationcontentclass = contentinactive

        let personalaria = "false"
        let addressaria = "false"
        let customFieldsAria = "false"
        let communicationaria = "false"

        let displayPhone = "none"
        let displayFacebook = "none"
        if (this.props.requestPhone === 1) {
            displayPhone = "block"
        }

        if (this.props.requestFacebook === 1) {
            displayFacebook = "block"
        }

        if(this.state.activeStep === 0) {
            personalaria = "true"

            personaldataclass = tabactive
            personaldatacontentclass = contentactive

            addressclass = tabinactive
            addresscontentclass = contentinactive
            communicationclass = tabinactive
            communicationcontentclass = contentinactive
            customFieldsClass = tabinactive
            customFieldsContentClass = contentinactive
        } else if(this.state.activeStep === 1) {
            if (this.props.requestAddress === 1) {
                addressaria = "true"

                addressclass = tabactive
                addresscontentclass = contentactive

                communicationclass = tabinactive
                communicationcontentclass = contentinactive
                personaldataclass = tabinactive
                personaldatacontentclass = contentinactive
                customFieldsClass = tabinactive
                customFieldsContentClass = contentinactive
            } else if (this.props.customFields !== null) {
                customFieldsAria = "true"
                customFieldsClass = tabactive
                customFieldsContentClass = contentactive

                communicationclass = tabinactive
                communicationcontentclass = contentinactive
                personaldataclass = tabinactive
                personaldatacontentclass = contentinactive
            } else {
                communicationaria = "true"

                communicationclass = tabactive
                communicationcontentclass = contentactive

                personaldataclass = tabinactive
                personaldatacontentclass = contentinactive
                addressclass = tabinactive
                addresscontentclass = contentinactive
                customFieldsClass = tabinactive
                customFieldsContentClass = contentinactive
            }
        } else if (this.state.activeStep === 2) {
            if (this.props.customFields !== null) {
                customFieldsAria = "true"
                customFieldsClass = tabactive
                customFieldsContentClass = contentactive

                communicationclass = tabinactive
                communicationcontentclass = contentinactive
                personaldataclass = tabinactive
                personaldatacontentclass = contentinactive
            } else {
                communicationaria = "true"

                communicationclass = tabactive
                communicationcontentclass = contentactive
    
                personaldataclass = tabinactive
                personaldatacontentclass = contentinactive
                addressclass = tabinactive
                addresscontentclass = contentinactive
                customFieldsClass = tabinactive
                customFieldsContentClass = contentinactive
            }
        } else {
            communicationaria = "true"

            communicationclass = tabactive
            communicationcontentclass = contentactive

            personaldataclass = tabinactive
            personaldatacontentclass = contentinactive
            addressclass = tabinactive
            addresscontentclass = contentinactive
            customFieldsClass = tabinactive
            customFieldsContentClass = contentinactive
        }

        let nationalitySelect
        let addressCountrySelect
        let provinceSelect
        let citySelect
        let addressDiv
        let addressOption = ""
        let customFieldsDiv
        let customFieldsOption = ""

        if (this.state.countries === null) {
            nationalitySelect = ""
        } else {
            nationalitySelect = <div className="form-group col-md-4">
                <label for="selectCountry">Nacionalidad</label>
                <select id="selectCountry" className="form-control" value={this.state.nationality} onChange={this.onNationalityChanged}>
                    <option value="-1">Seleccioná una opción</option>
                    { this.state.countries.map((country) => (
                        <option value={country.id}>{country.name}</option>
                    )) 
                    }
                </select>
            </div>
        }

        if (this.props.requestAddress === 1) {

            if (this.state.countries === null) {
                addressCountrySelect = ""
            } else {
                addressCountrySelect = <div className="form-group col-md-4">
                    <label for="selectAddressCountry">País</label>
                    <select id="selectAddressCountry" className="form-control" value={this.state.country} onChange={this.onCountryChanged}>
                        <option value="-1">Seleccioná una opción</option>
                        { this.state.countries.map((country) => (
                            <option value={country.id}>{country.name}</option>
                        )) 
                        }
                    </select>
                </div>
            }

            if (this.state.provinces === null) {
                provinceSelect = ""
            } else {
                provinceSelect = <div className="form-group col-md-4">
                    <label for="selectCountry">Provincia</label>
                    <select id="selectCountry" className="form-control" value={this.state.province} onChange={this.onProvinceChanged}>
                        <option value="-1">Seleccioná una opción</option>
                        { this.state.provinces.map((province) => (
                            <option value={province.id}>{province.name}</option>
                        )) 
                        }
                    </select>
                </div>
            }

            if (this.state.cities === null) {
                citySelect = ""
            } else {
                citySelect = <div className="form-group col-md-4">
                    <label for="selectCountry">Ciudad</label>
                    <select id="selectCountry" className="form-control" value={this.state.locality} onChange={this.onLocalityChanged}>
                        <option value="-1">Seleccioná una opción</option>
                        { this.state.cities.map((city) => (
                            <option value={city.id}>{city.name}</option>
                        )) 
                        }
                    </select>
                </div>
            }

            addressOption = <li class="nav-item" role="presentation">
                <button 
                    class={addressclass} 
                    id="address-tab" 
                    data-bs-toggle="tab" 
                    data-bs-target="#address" 
                    type="button" 
                    role="tab" 
                    aria-controls="address" 
                    aria-selected={addressaria}
                >
                    Domicilio
                </button>
            </li>                 
            addressDiv = <div style={{padding: "1em 1em 0em 1em"}} class={addresscontentclass} id="address" role="tabpanel" aria-labelledby="address-tab">
                <div className="form-row align-items-center">
                    {addressCountrySelect}
                    {provinceSelect}
                    {citySelect}
                </div>
                <div className="form-row align-items-center">
                    <div className="form-group col-md-3">
                        <label for="inputStreet">Calle</label>
                        <input className="form-control" type="text" 
                        id="inputStreet" placeholder="San Martin" 
                        value={this.state.street} onChange={this.onStreetChanged} />
                    </div>
                    <div className="form-group col-md-3">
                        <label for="inputNumber">Altura</label>
                        <input className="form-control" type="text" 
                        id="inputNumber" placeholder="100" 
                        value={this.state.number} onChange={this.onNumberChanged} />
                    </div>
                    <div className="form-group col-md-3">
                        <label for="inputAddressComments">Piso/Dpto</label>
                        <input className="form-control" type="text" 
                        id="inputAddressComments" placeholder="1A" 
                        value={this.state.addressComments} onChange={this.onAddressCommentsChanged} />
                    </div>
                    <div className="form-group col-md-3">
                        <label for="inputPostalCode">Código Postal</label>
                        <input className="form-control" type="text" 
                        id="inputPostalCode" placeholder="1111" 
                        value={this.state.postalCode} onChange={this.onPostalCodeChanged} />
                    </div>
                </div>
                <div className="form-row d-flex flex-row-reverse">
                    <div className="form-group">
                        <button type="submit" class="btn btn-primary float-right" onClick={this.nextStep.bind(this, this.state.activeStep)}>Siguiente</button>
                    </div>
                    <div className="form-group" style={{marginRight: "1em"}}>
                        <button type="submit" class="btn btn-primary float-right" onClick={this.prevStep.bind(this, this.state.activeStep)}>Anterior</button>
                    </div>
                </div>
            </div>
        }
        if (this.props.customFields !== null) {

            customFieldsOption = <li class="nav-item" role="presentation">
                <button
                    class={customFieldsClass}
                    id="custom-fields-tab"
                    data-bs-toggle="tab" 
                    data-bs-target="#custom-fields"
                    type="button" 
                    role="tab" 
                    aria-controls="custom-fields" 
                    aria-selected={customFieldsAria}
                >
                    Más datos
                </button>
            </li>
            customFieldsDiv = (
                <div
                  className={`p-4 mb-4 ${customFieldsContentClass}`}
                  id="custom-fields"
                  role="tabpanel"
                  aria-labelledby="custom-fields-tab"
                >
                  <div className="form-row">
                    {this.props.customFields &&
                      this.props.customFields.map((field, index) => (
                        <div
                          key={index}
                          className="mb-3 col-md-6"
                        >
                          <strong>{field.name}</strong>
                          {field.type === customFieldUtils.CustomFieldTypes.TEXT ? (
                            <form id={index} onSubmit={(e) => this.onChangeCustomFieldText(e, field)}>
                                <input
                                    id={index}
                                    type="text"
                                    className="form-control mb-2"
                                    value={this.state[field.name] ? this.state[field.name] : ''}
                                    onChange={(e) => this.manageCustomFieldChange(e, field)}
                                />
                                <button className="btn btn-primary btn-sm" id={index} type="submit">Guardar</button>
                            </form>
                          ) : field.type === customFieldUtils.CustomFieldTypes.MULTIPLE_CHOICE ? (
                            field.multiple_choice_field.split(',').map((option, optionIndex) => (
                              <div
                                key={optionIndex}
                                className="form-check mb-2"
                              >
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={`option-${optionIndex}`}
                                  value={option}
                                  checked={this.isOptionChecked(field.name, option)}
                                  onChange={(e) => this.manageCustomFieldChange(e, field, option)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`option-${optionIndex}`}
                                >
                                  {option.trim()}
                                </label>
                              </div>
                            ))
                          ) : field.type === customFieldUtils.CustomFieldTypes.DROPDOWN && (
                            <div className="form-group col-md-6" style={{display: "contents"}}>
                                <select 
                                    id="options" 
                                    name="options"
                                    className="form-control mw-50"
                                    onChange={(e) => this.manageCustomFieldChange(e, field)}
                                >
                                    <option value=''>{'Seleccionar'}</option>
                                    {field.multiple_choice_field.split(',').map((option, optionIndex) => (
                                        <option value={option}>{customFieldUtils.CapitalizeFirstLetter(option)}</option>
                                    ))}
                                </select>
                            </div>
                          )
                        }
                        </div>
                      ))}
                    <div className="form-row d-flex flex-row-reverse">
                        <div className="form-group">
                            <button type="submit" class="btn btn-primary float-right" onClick={this.nextStep.bind(this, this.state.activeStep)}>Siguiente</button>
                        </div>
                        <div className="form-group" style={{marginRight: "1em"}}>
                            <button type="submit" class="btn btn-primary float-right" onClick={this.prevStep.bind(this, this.state.activeStep)}>Anterior</button>
                        </div>
                    </div>
                  </div>
                </div>
            );   
        }
        let daysOfMonth = new DaysOfMonth().GetDaysOfMonth()
        return (
            <div className="container">
                <div className="row" style={{marginTop: "0.5em"}}>
                    <div className="col text-left">
                        <h3 className="h6 text-gray-800">Administración de Clientes {this.props.storeName}</h3>
                    </div>
                </div>
                <div className="row">
                    <img id="imgHeader" style={{width: "100%", height: "auto"}} src={this.props.bannerImage} />
                </div>
                <div className="row justify-content-center" style={{marginTop: "0.5em", marginBottom: "1em"}}>
                    <div>
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class={personaldataclass} id="personaldata-tab" data-bs-toggle="tab" data-bs-target="#personaldata" type="button" role="tab" aria-controls="personaldata" aria-selected={personalaria}>Datos Personales</button>
                            </li>
                            {addressOption}
                            {this.props.customFields && customFieldsOption}
                            <li class="nav-item" role="presentation">
                                <button class={communicationclass} id="communication-tab" data-bs-toggle="tab" data-bs-target="#communication" type="button" role="tab" aria-controls="communication" aria-selected={communicationaria}>Comunicación</button>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div style={{padding: "1em 1em 0em 1em"}} class={personaldatacontentclass} id="personaldata" role="tabpanel" aria-labelledby="personaldata-tab">
                            <div className="form-row align-items-center">
                                    <div className="form-group col-md-3">
                                        <label for="selectIdentificationType">Tipo Documento</label>
                                        <select id="selectIdentificationType" className="form-control" value={this.state.identificationType} onChange={this.onIdentificationTypeChanged}>
                                            <option value="DNI">DNI</option>
                                            <option value="PASSPORT">Pasaporte</option>
                                            <option value="CUIL">CUIL</option>
                                            <option value="CEDULA">Cédula de Identidad</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label for="inputIdentification">Documento</label>
                                        <input className="form-control" type="text" 
                                        id="inputIdentification" placeholder="12345678" 
                                        onKeyUp={this.onKeyUp}
                                        onBlur={this.onFocusOut} 
                                        value={this.state.identification} onChange={this.onIdentificationChanged} />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label for="selectSex">Sexo</label>
                                        <select id="selectSex" className="form-control" readOnly={this.state.exists} value={this.state.sex} onChange={this.onSexChanged}>
                                            <option value="M">Hombre</option>
                                            <option value="F">Mujer</option>
                                            <option value="I">Indistinto</option>
                                        </select>
                                    </div>

                                    <div className="form-group col-md-3">
                                        <label htmlFor="selectDay">Fecha de Nacimiento</label>
                                        <div className="form-row">
                                            <div className="col">
                                                <select id="selectDay" className="form-control" value={this.state.born_day} onChange={this.onBornDayChanged}>
                                                    <option value="NULL">-- Día --</option>
                                                    {daysOfMonth.map(day => (
                                                        <option value={day} key={day}>{day}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col">
                                                <select id="selectMonth" className="form-control" value={this.state.born_month} onChange={this.onBornMonthChanged}>
                                                    <option value="NULL">-- Mes --</option>
                                                    <option value="01">Enero</option>
                                                    <option value="02">Febrero</option>
                                                    <option value="03">Marzo</option>
                                                    <option value="04">Abril</option>
                                                    <option value="05">Mayo</option>
                                                    <option value="06">Junio</option>
                                                    <option value="07">Julio</option>
                                                    <option value="08">Agosto</option>
                                                    <option value="09">Septiembre</option>
                                                    <option value="10">Octubre</option>
                                                    <option value="11">Noviembre</option>
                                                    <option value="12">Diciembre</option>
                                                </select>
                                            </div>
                                            <div className="col">
                                                <select id="selectYear" className="form-control" value={this.state.born_year} onChange={this.onBornYearChanged}>
                                                    <option value="NULL">-- Año --</option>
                                                    {Array.from({ length: 100 }, (_, k) => Math.abs(k - 2022)).map((col) => (
                                                        <option value={col} key={col}>{col}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                                <div className="form-row align-items-center">
                                    <div className="form-group col-md-4">
                                        <label for="inputName">Nombre</label>
                                        <input className="form-control" type="text" 
                                        id="inputName" placeholder="Juan" readOnly={this.state.exists}
                                        value={this.state.name} onChange={this.onNameChanged} />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label for="inputLastName">Apellido</label>
                                        <input className="form-control" type="text" 
                                        id="inputLastName" placeholder="Perez" readOnly={this.state.exists}
                                        value={this.state.lastName} onChange={this.onLastNameChanged} />
                                    </div>
                                    {nationalitySelect}
                                </div>
                                <div className="form-row d-flex flex-row-reverse">
                                    <div className="form-group">
                                        <button type="submit" class="btn btn-primary float-right" onClick={this.nextStep.bind(this, this.state.activeStep)}>Siguiente</button>
                                    </div>
                                </div>
                            </div>
                            {addressDiv}
                            {customFieldsDiv}
                            <div style={{padding: "1em 1em 0em 1em"}} class={communicationcontentclass} id="communication" role="tabpanel" aria-labelledby="communication-tab">
                                <div className="form-row align-items-center">
                                    <div className="form-group col-md-12">
                                        <label for="inputEmail">Email</label>
                                        <input className="form-control" type="text" 
                                        id="inputEmail" placeholder="tu@email.com" readOnly={this.state.exists}
                                        value={this.state.email} onChange={this.onEmailChanged} />
                                    </div>
                                    <div className="form-group col-md-12" style={{display: displayPhone}}>
                                        <label for="inputPhone">Celular</label>
                                        <input className="form-control" type="text" readOnly={this.state.exists}
                                        id="inputPhone" placeholder="+54 9 11 1234 7965" 
                                        value={this.state.phone} onChange={this.onPhoneChanged} />
                                    </div>
                                    <div className="form-group col-md-12" style={{display: displayFacebook}}>
                                        <label for="inputFacebook">Facebook</label>
                                        <input className="form-control" type="text" 
                                        id="inputFacebook" placeholder="http://facebook.com/tuperfil" 
                                        value={this.state.facebook} onChange={this.onFacebookChanged} />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <p>{this.props.termsAndConditions}</p>
                                        <div className='row d-flex flex-wrap flex-column'>
                                            {this.props.termsAndConditionsUrl && 
                                                <a className='mb-1' href={this.props.termsAndConditionsUrl} target='_blank'>Ir a términos y condiciones</a>
                                            }
                                            <label>
                                                <input 
                                                    type="checkbox" 
                                                    className="mr-2"
                                                    checked={this.state.acceptTermsAndConditions}
                                                    onChange={this.handleTermsAndConditionsCheckboxChange}
                                                    required
                                                />
                                                    Aceptar términos y condiciones
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row d-flex flex-row-reverse">
                                    <div className="form-group">
                                        <button type="submit" class="btn btn-primary float-right" onClick={this.onConfirmButtonClicked}>Confirmar</button>
                                    </div>
                                    <div className="form-group" style={{marginRight: "1em"}}>
                                        <button type="submit" class="btn btn-primary float-right" onClick={this.prevStep.bind(this, this.state.activeStep)}>Anterior</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        );
    }
}
export default CrearCliente;
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import SessionContext from '../../SessionContext';
import { trackPromise } from "react-promise-tracker";
import Tooltip from "@material-ui/core/Tooltip";
import DynamicCustomFields from "./DynamicCustomFields";
import FileUtils from "../../utils/fileUtils";

const fileToBase64AndURL = new FileUtils();

// Componente de alerta usando Material-UI
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Form extends Component {
  static contextType = SessionContext;
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        // Estado inicial del formulario
        id: "",
        // Obtenemos el storeId desde los params de la ruta, si existe
        store_id: this.props.match?.params?.storeId || "",
        form_name: "",
        web_name: "",
        // Campos para manejar el banner como en StoreNewClient.js:
        file: "", // URL local con URL.createObjectURL(file)
        base64: "", // Cadena base64 de la imagen
        url: "", // URL previa (si aplica, podría venir de la base de datos o inicial)
        title: "",
        description: "",
        form_fields: [], // Aquí se almacenarán los campos personalizados que provienen del componente hijo
      },
      snackbar: {
        open: false,
        message: "",
        severity: "success",
      },
      formUrl: "",
    };

    // Enlaces de this para los métodos
    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
  }

  componentDidMount() {
    // const store_name = sessionStorage.getItem("store_name");
    // Si existe storeId en los parámetros, actualizamos el estado
    const {
      match: { params },
    } = this.props;
    if (params.storeId) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          store_id: params.storeId,
        },
      }));
    }

    // Generamos la URL del formulario inicial
    this.getFormUrl();
  }

  // Genera la URL del formulario según el web_name
  getFormUrl = () => {
    const { web_name } = this.state.formData;
    const store_name = sessionStorage.getItem("store_name");
    const formUrl = `https://www.fidely.online/${store_name}/formulario/${web_name}`;
    // const formUrl = `https://www.fidely.online/store/${store_id}/formulario/${web_name}`; // storeName?
    this.setState({ formUrl });
  };

  // Maneja cambios en inputs de texto
  handleChange(e) {
    const { name, value } = e.target;

    this.setState((prevState) => {
      const updatedFormData = { ...prevState.formData, [name]: value };
      return {
        formData: updatedFormData,
        // Actualiza la URL del formulario si el web_name cambia
        formUrl:
          name === "web_name"
            ? `https://www.fidely.online/store/formulario/${value.toLowerCase()}`
            : prevState.formUrl,
      };
    });
  }

  // Maneja la selección del archivo para el banner, validando el tamaño
  handleFileChange(e) {
    let file = e.target.files[0];
    fileToBase64AndURL.fileToBase64AndURL(file)
      .then(({ fileURL, base64 }) => {
        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            file: fileURL,
            base64: base64,
          },
        }));
      })
      .catch((err) => alert(err));
  }

  // Maneja el envío del formulario
  // Lógica similar a StoreNewClient.js:
  // Si hay base64, se usa base64.
  // Si no hay base64, se usa url o file.
  handleSubmit(e) {
    e.preventDefault();
    const {
      id,
      store_id,
      form_name,
      web_name,
      url,
      file,
      base64,
      title,
      description,
      form_fields,
    } = this.state.formData;
    const { formUrl } = this.state;


    let image = "";
    if (base64 !== "") {
      image = base64;
    } else {
      image = url !== "" ? url : file;
    }
    const data = {
      store_id: store_id,
      form_name: form_name,
      web_name: web_name,
      formUrl: formUrl,
      title: title,
      description: description,
      form_fields: form_fields, 
      banner: image, 
    };
    // trackPromise para mostrar algún indicador de carga si se necesita
    const postUrl = `${this.context.hubURL}/stores/${store_id}/forms/create`
    trackPromise(
      axios
        .post(postUrl, data, { headers: { 'Access-Control-Allow-Origin': '*' } })
        .then((res) => {
          // Éxito: mostrar notificación
          this.setState({
            snackbar: {
              open: true,
              message: "Formulario enviado con éxito!",
              severity: "success",
            },
          });
        })
        .catch((error) => {
          // Error: mostrar notificación
          this.setState({
            snackbar: {
              open: true,
              message: "Error al enviar el formulario.",
              severity: "error",
            },
          });
        })
    );
  }

  // Cierra la notificación Snackbar
  handleCloseSnackbar() {
    this.setState({ snackbar: { ...this.state.snackbar, open: false } });
  }

  // Callback desde DynamicCustomFields para actualizar form_fields
  updateFormFields = (newFields) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        form_fields: newFields,
      },
    }));
  };

  render() {
    const { formData, formUrl, snackbar } = this.state;

  // Validar campos obligatorios (form_name, web_name, title, description)
  // const isFormValid =
  //   formData.form_name.trim() !== "" &&
  //   formData.web_name.trim() !== "" &&
  //   formData.title.trim() !== "" &&
  //   formData.description.trim() !== "";

    return (
      <div className="container-fluid">
        <h1 className="mt-4 mb-4">Crear Formulario</h1>
        <form onSubmit={this.handleSubmit}>
          <div className="form-row">
            {/* Nombre Formulario */}
            <div className="form-group col-md-6">
              <label htmlFor="form_name" style={{ fontWeight: "bold" }}>
                Nombre Formulario
              </label>
              <input
                className="form-control"
                type="text"
                id="form_name"
                name="form_name"
                value={formData.form_name}
                onChange={this.handleChange}
                required
              />
            </div>

            {/* Nombre Web */}
            <div className="form-group col-md-6">
              <label htmlFor="web_name" style={{ fontWeight: "bold" }}>
                Nombre Web
              </label>
              <input
                className="form-control"
                type="text"
                id="web_name"
                name="web_name"
                value={formData.web_name}
                onChange={this.handleChange}
                // required // Es requerido?
              />
              <div className="form-group col-md-12">
                <label
                  htmlFor="form_url"
                  style={{
                    fontSize: "0.875rem",
                    fontWeight: "normal",
                  }}
                >
                  Recordar que la URL para acceder será:{" "}
                  <span style={{ fontWeight: "bold" }}>{formUrl}</span>
                </label>
              </div>
            </div>
          </div>

          {/* Banner */}
          <div className="form-row align-items-center">
            <div className="form-group col-md-6">
              <label htmlFor="banner" style={{ fontWeight: "bold" }}>
                Banner (Max. 10MB)
              </label>
              <div className="input-group">
                <div className="input-group-prepend">
                  {/* Ícono para el input de archivo */}
                  <span className="input-group-text">
                    <i
                      className="fas fa-image"
                      style={{ fontSize: "1.5em" }}
                    ></i>
                  </span>
                </div>
                <input
                  type="file"
                  className="form-control"
                  id="banner"
                  name="banner"
                  onChange={this.handleFileChange}
                />
              </div>
            </div>

            {/* Vista previa del banner si existe file o url */}
            {formData.file && (
              <div className="form-group col-md-2 d-flex align-items-center ml-2">
                <img
                  src={formData.file}
                  alt="Banner Preview"
                  style={{
                    width: "100%",
                    height: "auto",
                    border: "1px solid #ccc", // Borde sutil en gris
                    borderRadius: "4px", // Esquinas ligeramente redondeadas
                    padding: "4px", // Un poco de padding interno
                    backgroundColor: "#f9f9f9", // Un fondo muy suave, para destacar la imagen
                  }}
                />
              </div>
            )}
          </div>

          {/* Título y Descripción */}
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="title" style={{ fontWeight: "bold" }}>
                Título
              </label>
              <input
                className="form-control"
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="description" style={{ fontWeight: "bold" }}>
                Descripción
              </label>
              <input
                className="form-control"
                type="text"
                id="description"
                name="description"
                value={formData.description}
                onChange={this.handleChange}
                required
              />
            </div>
          </div>

          <hr />

          {/* Campos personalizados */}
          <div className="row">
            {/* Componente hijo que gestiona los campos dinámicos.
                El padre recibe las actualizaciones vía onFieldsChange */}
            <DynamicCustomFields onFieldsChange={this.updateFormFields} />
          </div>

          {/* Botón para enviar el formulario */}
          <div className="form-group">
            <Tooltip
              title="Confirmar la creación del formulario luego de completar los campos."
              placement="right"
              arrow
            >
              <button
                type="submit"
                className="btn btn-primary mt-1"
                // disabled={!isFormValid} // Deshabilita el botón si no son válidos los campos obligatorios
              >
                Confirmar
              </button>
            </Tooltip>
          </div>
        </form>

        {/* Snackbar para notificaciones */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={this.handleCloseSnackbar}
        >
          <Alert
            onClose={this.handleCloseSnackbar}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default withRouter(Form);

import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { addMonths, format } from 'date-fns';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NewStamp extends Component {
    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            due_days: '',
            min_amount: '',
            
            fullfile: '',
            fullbase64: '',
            fullurl: '',

            emptyfile: '',
            emptybase64: '',
            emptyurl: '',

            title: '',
            description: '',
            
            open: false,
            someChanges: false,
            urlToRedirect: null,
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        
        this.onChangeDueDays = this.onChangeDueDays.bind(this)
        this.onChangeMinAmount = this.onChangeMinAmount.bind(this)
        
        this.onChangeTitle = this.onChangeTitle.bind(this)
        this.onChangeDescription = this.onChangeDescription.bind(this)

        this.setOpen = this.setOpen.bind(this)
        this.goToList = this.goToList.bind(this)
        
        this.uploadEmptyFile = this.uploadEmptyFile.bind(this)
        this.uploadFullFile = this.uploadFullFile.bind(this)
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        if (params.stampId != null) {
            trackPromise(
                axios.get(`${this.context.hubURL}/stores/${params.storeId}/stamps/${params.stampId}`, {
                    headers: {
                    'Access-Control-Allow-Origin': '*',
                    }
                })
                    .then(res => {
                        this.setState({ 
                            min_amount: res.data["min_amount"], 
                            due_days: res.data["due_days"], 
                            fullurl: res.data["full_image_url"],
                            emptyurl: res.data["empty_image_url"],
                            title: res.data["title"],
                            description: res.data["description"],
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            );
        }
    }

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault()

        if (this.state.someChanges === false) {
            this.props.history.go(-1);
            return
        }

        var emptyimage = ''
        if (this.state.fullbase64 !== '') {
            emptyimage = this.state.emptybase64;
        } else {
            emptyimage = this.state.emptyurl;
        }

        var fullimage = ''
        if (this.state.fullbase64 !== '') {
            fullimage = this.state.fullbase64;
        } else {
            fullimage = this.state.fullurl;
        }

        const obj = {
            min_amount: this.state.min_amount.toString(),
            due_days: this.state.due_days.toString(),
            empty_image_content: emptyimage,
            full_image_content: fullimage,
            title: this.state.title,
            description: this.state.description
        };

        var url = `${this.context.hubURL}/stores/${params.storeId}/stamps`;
        var message = 'Estampa agregada!';
        if (params.stampId != null) {
            url = url + `/${params.stampId}`
            message = 'Estampa editada!';
        }

        trackPromise(
            axios.post(url, obj, {
                headers: { 'Access-Control-Allow-Origin': '*', } })
                .then((res) => {
                    this.setState({ open: true, message: message })
                }).catch((error) => {
                    console.log(error)
                })
        );
    }

    onChangeMinAmount(e) {
        this.setState({ min_amount: e.target.value, someChanges:true })
    }

    onChangeDueDays(e) {
        this.setState({ due_days: e.target.value, someChanges:true })
    }

    onChangeTitle(e) {
        this.setState({ title: e.target.value, someChanges:true })
    }

    onChangeDescription(e) {
        this.setState({ description: e.target.value, someChanges:true })
    }

    setOpen(value) {
        this.setState({ open: value })
    }

    uploadEmptyFile(e) {
        let file = e.target.files[0];

        var size = file.size / 1024 / 1024; // in MB
        if (size > 10) {
            alert('Archivo demasiado grande.');
        } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    emptyfile: URL.createObjectURL(file),
                    emptybase64: reader.result, 
                    someChanges: true
                });
            }
        }
    }

    uploadFullFile(e) {
        let file = e.target.files[0];

        var size = file.size / 1024 / 1024; // in MB
        if (size > 10) {
            alert('Archivo demasiado grande.');
        } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    fullfile: URL.createObjectURL(file),
                    fullbase64: reader.result, 
                    someChanges: true
                });
            }
        }
    }

    render() {
        if (this.state.urlToRedirect !== null) {
            return <Redirect to={this.state.urlToRedirect} />
        }

        let previewDiv, defined_class;
        if (this.state.fullfile) {
            previewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.fullfile} alt='' /></div>
            defined_class = "col-md-9"
        } else if (this.state.fullurl !== '') {
            previewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.fullurl} alt='' /></div>
            defined_class = "col-md-9"
        } else {
            defined_class = "col-md-12"
        }

        let emptypreviewDiv, emptydefined_class;
        if (this.state.emptyfile) {
            emptypreviewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.emptyfile} alt='' /></div>
            emptydefined_class = "col-md-9"
        } else if (this.state.emptyurl !== '') {
            emptypreviewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.emptyurl} alt='' /></div>
            emptydefined_class = "col-md-9"
        } else {
            emptydefined_class = "col-md-12"
        }

        return (
            <div className="container-fluid"> 
                <h1 className="mt-4">Nueva Estampa</h1>
                <br />
    
                <div className="row">
                    <div className="col">
                        <form onSubmit={this.onFormSubmitted}>

                            <div className="form-row align-items-center">
                                <div className="form-group col-md-6">
                                    <div className="row align-items-center">
                                        {emptypreviewDiv}
                                        <div className={emptydefined_class}>
                                            <label htmlFor="inputEmptyFile">Estampa Vacía (Max. 10MB)</label>
                                            <input type="file" id="inputEmptyFile" className="form-control" onChange={this.uploadEmptyFile} />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group col-md-6">
                                    <div className="row align-items-center">
                                        {previewDiv}
                                        <div className={defined_class}>
                                            <label htmlFor="inputFile">Estampa (Max. 10MB)</label>
                                            <input type="file" id="inputFile" className="form-control" onChange={this.uploadFullFile} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputMinAmount">Monto Mínimo</label>
                                    <input className="form-control" type="text" 
                                    id="inputMinAmount" placeholder="Monto mínimo" 
                                    value={this.state.min_amount} onChange={this.onChangeMinAmount} />
                                </div>
                                
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputDueDays">Duración (en días)</label>
                                    <input className="form-control" type="text" 
                                    id="inputDueDays" placeholder="Días duración" 
                                    value={this.state.due_days} onChange={this.onChangeDueDays} />
                                </div>
                            </div>

                            <div className="form-row">
                            <div className="form-group col-md-6">
                                    <label htmlFor="inputTitle">Título</label>
                                    <input className="form-control" type="text" 
                                    id="inputTitle" placeholder="Titulo" 
                                    value={this.state.title} onChange={this.onChangeTitle} />
                                </div>
                                
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputDescription">Descipción</label>
                                    <input className="form-control" type="text" 
                                    id="inputDescription" placeholder="Descipción" 
                                    value={this.state.description} onChange={this.onChangeDescription} />
                                </div>
                            </div>

                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
    
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.goToList()}>
                    <Alert onClose={() => this.goToList()} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>

            </div>
        )
    }

    goToList() {
        const { match: { params } } = this.props;
        this.setState({ urlToRedirect: `/stores/${params.storeId}/stamps` })
    }

}

export default NewStamp
import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import YesNoSwitch from '../YesNoSwitch';
import { Redirect } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class WebApp extends Component {
    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            id: 0,
            webappname: '',
            file: '',
            base64: '',
            url: '',
            favinconfile: '',
            favinconbase64: '',
            favinconurl: '',
            secondaryLogofile: '',
            secondaryLogobase64: '',
            secondaryLogourl: '',
            primaryColor: '',
            secondaryColor: '',
            letterColor: '',
            primary_background_color: '',
            primary_button_color: '',
            primary_button_text_color: '',
            secondary_background_color: '',
            secondary_button_color: '',
            secondary_button_text_color: '',
            allow_new_users: 1,
            open: false,
            someChanges: false,
            message: '',
            openStores: false,
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        
        this.onChangeWebappName = this.onChangeWebappName.bind(this)

        this.onChangePrimaryColor = this.onChangePrimaryColor.bind(this)
        this.onChangeSecondaryColor = this.onChangeSecondaryColor.bind(this)
        this.onChangeLetterColor = this.onChangeLetterColor.bind(this)
        
        this.onChangePrimaryBackgroundColor = this.onChangePrimaryBackgroundColor.bind(this)
        this.onChangePrimaryButtonColor = this.onChangePrimaryButtonColor.bind(this)
        this.onChangePrimaryButtonTextColor = this.onChangePrimaryButtonTextColor.bind(this)
        this.onChangeSecondaryBackgroundColor = this.onChangeSecondaryBackgroundColor.bind(this)
        this.onChangeSecondaryButtonColor = this.onChangeSecondaryButtonColor.bind(this)
        this.onChangeSecondaryButtonTextColor = this.onChangeSecondaryButtonTextColor.bind(this)

        this.onChangeAllowNewUsers = this.onChangeAllowNewUsers.bind(this)
     
        this.uploadSingleFile = this.uploadSingleFile.bind(this)
        this.uploadFavincon = this.uploadFavincon.bind(this)
        this.uploadSecondaryLogo = this.uploadSecondaryLogo.bind(this)
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        if (params.storeId !== undefined) {
            trackPromise(
                axios.get(`${this.context.hubURL}/stores/${params.storeId}/webapp`, {
                    headers: { 'Access-Control-Allow-Origin': '*' }
                })
                .then(res => {
                    this.setState({ 
                        webappname: res.data.url,
                        url: res.data.logo_url,
                        secondaryLogourl: res.data.secondary_logo_url,
                        faviconurl: res.data.favicon_url,
                        primaryColor: res.data.primary_color,
                        secondaryColor: res.data.secondary_color,
                        letterColor: res.data.letter_color,
                        primary_background_color: res.data.primary_background_color,
                        primary_button_color: res.data.primary_button_color,
                        primary_button_text_color: res.data.primary_button_text_color,
                        secondary_background_color: res.data.secondary_background_color,
                        secondary_button_color: res.data.secondary_button_color,
                        secondary_button_text_color: res.data.secondary_button_text_color,
                        allow_new_users: res.data.allow_new_user,
                    });
                })
                .catch(function (error) {
                    console.log(error);
                })
            );
        }
    }


    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault()

        if (this.state.someChanges === false) {
            this.props.history.go(-1);
            return
        } else {
            var image = ''
            if (this.state.base64 !== '') {
                image = this.state.base64;
            } else {
                image = this.state.url;
            }

            var faviconimage = ''
            if (this.state.favinconbase64 !== '') {
                faviconimage = this.state.favinconbase64;
            } else {
                faviconimage = this.state.favinconurl;
            }

            var secondaryimage = ''
            if (this.state.secondaryLogobase64 !== '') {
                secondaryimage = this.state.secondaryLogobase64;
            } else {
                secondaryimage = this.state.secondaryLogourl;
            }

            const object = {
                url: this.state.webappname,
                logo_url: image,
                favicon_url: faviconimage,
                secondary_logo_url: secondaryimage,
                primary_color: this.state.primaryColor,
                secondary_color: this.state.secondaryColor,
                letter_color: this.state.letterColor,
                primary_background_color: this.state.primary_background_color,
                primary_button_color: this.state.primary_button_color,
                primary_button_text_color: this.state.primary_button_text_color,
                secondary_background_color: this.state.secondary_background_color,
                secondary_button_color: this.state.secondary_button_color,
                secondary_button_text_color: this.state.secondary_button_text_color,
                allow_new_user: this.state.allow_new_users,
            };

            if (params.storeId !== 0) {
                trackPromise(
                    axios.post(`${this.context.hubURL}/stores/${params.storeId}/webapp`, object, { headers: { 'Access-Control-Allow-Origin': '*' } })
                    .then((res) => {
                        this.setState({ open: true, message: 'Webapp actualizada!' })
                    }).catch((error) => {
                        console.log(error)
                    })
                );
            }
        }
    }

    onChangeAllowNewUsers(e) {
        let v = e ? 1 : 0
        this.setState({ allow_new_users: v, someChanges: true })
    }

    onChangeWebappName(e) {
        this.setState({ webappname: e.target.value, someChanges: true })
    }

    onChangePrimaryColor(e) {
        this.setState({ primaryColor: e.target.value, someChanges: true })
    }

    onChangeSecondaryColor(e) {
        this.setState({ secondaryColor: e.target.value, someChanges: true })
    }

    onChangeLetterColor(e) {
        this.setState({ letterColor: e.target.value, someChanges: true })
    }

    onChangePrimaryBackgroundColor(e) {
        this.setState({ primary_background_color: e.target.value, someChanges: true })
    }
    onChangePrimaryButtonColor(e) {
        this.setState({ primary_button_color: e.target.value, someChanges: true })
    }
    onChangePrimaryButtonTextColor(e) {
        this.setState({ primary_button_text_color: e.target.value, someChanges: true })
    }
    onChangeSecondaryBackgroundColor(e) {
        this.setState({ secondary_background_color: e.target.value, someChanges: true })
    }
    onChangeSecondaryButtonColor(e) {
        this.setState({ secondary_button_color: e.target.value, someChanges: true })
    }
    onChangeSecondaryButtonTextColor(e) {
        this.setState({ secondary_button_text_color: e.target.value, someChanges: true })
    }
    

    uploadSingleFile(e) {
        let file = e.target.files[0];

        var size = file.size / 1024 / 1024; // in MB
        if (size > 10) {
            alert('Archivo demasiado grande.');
        } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    file: URL.createObjectURL(file),
                    base64: reader.result, 
                    someChanges: true
                });
            }
        }
    }

    uploadFavincon(e) {
        let file = e.target.files[0];

        var size = file.size / 1024 / 1024; // in MB
        if (size > 10) {
            alert('Archivo demasiado grande.');
        } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    favinconfile: URL.createObjectURL(file),
                    favinconbase64: reader.result, 
                    someChanges: true
                });
            }
        }
    }

    uploadSecondaryLogo(e) {
        let file = e.target.files[0];

        var size = file.size / 1024 / 1024; // in MB
        if (size > 10) {
            alert('Archivo demasiado grande.');
        } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    secondaryLogofile: URL.createObjectURL(file),
                    secondaryLogobase64: reader.result, 
                    someChanges: true
                });
            }
        }
    }

    render() {
        if (this.state.openStores) {
            return <Redirect to="/stores" />
        }

        let previewDiv, defined_class, previewDivFavicon, defined_class_favicon, previewDivSecondary, defined_class_secondary;
        
        if (this.state.file) {
            previewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.file} alt='' /></div>
            defined_class = "col-md-9"
        } else if (this.state.url !== '') {
            previewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.url} alt='' /></div>
            defined_class = "col-md-9"
        } else {
            defined_class = "col-md-12"
        }

        if (this.state.favinconfile) {
            previewDivFavicon = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.favinconfile} alt='' /></div>
            defined_class_favicon = "col-md-9"
        } else if (this.state.faviconurl !== '') {
            previewDivFavicon = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.faviconurl} alt='' /></div>
            defined_class_favicon = "col-md-9"
        } else {
            defined_class_favicon = "col-md-12"
        }

        if (this.state.secondaryLogofile) {
            previewDivSecondary = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.secondaryLogofile} alt='' /></div>
            defined_class_secondary = "col-md-9"
        } else if (this.state.secondaryLogourl !== '') {
            previewDivSecondary = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.secondaryLogourl} alt='' /></div>
            defined_class_secondary = "col-md-9"
        } else {
            defined_class_secondary = "col-md-12"
        }

        return (
            <div className="container-fluid">
                <h1 className="mt-4">Configuración Webapp</h1>
                <br />
    
                <form onSubmit={this.onFormSubmitted}>
                    <div className="form-row align-items-center">
                        <div className="form-group col-md-6">
                            <label htmlFor="inputName">Nombre Webapp (.com/nombre)</label>
                            <input className="form-control" type="text" 
                            id="inputName" placeholder="Fantasia" 
                            value={this.state.webappname} onChange={this.onChangeWebappName} />
                        </div>

                        <div className="form-group col-md-6">
                            <div className="row align-items-center">
                                {previewDiv}
                                <div className={defined_class}>
                                    <label htmlFor="inputFile">Logo (Max. 10MB)</label>
                                    <input type="file" id="inputFile" className="form-control" onChange={this.uploadSingleFile} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-row align-items-center">
                        <div className="form-group col-md-6">
                            <div className="row align-items-center">
                                {previewDivSecondary}
                                <div className={defined_class_secondary}>
                                    <label htmlFor="inputFileSecondary">Logo Secundario (Max. 10MB)</label>
                                    <input type="file" id="inputFileSecondary" className="form-control" onChange={this.uploadSecondaryLogo} />
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-md-6">
                            <div className="row align-items-center">
                                {previewDivFavicon}
                                <div className={defined_class_favicon}>
                                    <label htmlFor="inputFavicon">Favicon (Max. 10MB)</label>
                                    <input type="file" id="inputFavicon" className="form-control" onChange={this.uploadFavincon} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label for="inputPrimaryColor">Color principal (primary_color)</label>
                            <input className="form-control" type="color" id="inputPrimaryColor" 
                            value={this.state.primaryColor} onChange={this.onChangePrimaryColor} />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputSecondaryColor">Color Secundario (secondary_color)</label>
                            <input className="form-control" type="color" id="inputSecondaryColor"
                            value={this.state.secondaryColor} onChange={this.onChangeSecondaryColor} />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputLetterColor">Color de Letra (letter_color)</label>
                            <input className="form-control" type="color" id="inputLetterColor"
                            value={this.state.letterColor} onChange={this.onChangeLetterColor} />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label for="inputPrimaryBackgroundColor">Primary Background Color</label>
                            <input className="form-control" type="color" id="inputPrimaryBackgroundColor" 
                            value={this.state.primary_background_color} onChange={this.onChangePrimaryBackgroundColor} />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputPrimaryButtonColor">Primary Button Color</label>
                            <input className="form-control" type="color" id="inputPrimaryButtonColor"
                            value={this.state.primary_button_color} onChange={this.onChangePrimaryButtonColor} />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputPrimaryButtonTextColor">Primary Button Text Color</label>
                            <input className="form-control" type="color" id="inputPrimaryButtonTextColor"
                            value={this.state.primary_button_text_color} onChange={this.onChangePrimaryButtonTextColor} />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label for="inputSecondaryBackgroundColor">Secondary Background Color</label>
                            <input className="form-control" type="color" id="inputSecondaryBackgroundColor" 
                            value={this.state.secondary_background_color} onChange={this.onChangeSecondaryBackgroundColor} />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputSecondaryButtonColor">Secondary Button Color</label>
                            <input className="form-control" type="color" id="inputSecondaryButtonColor"
                            value={this.state.secondary_button_color} onChange={this.onChangeSecondaryButtonColor} />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputSecondaryButtonTextColor">Secondary Button Text Color</label>
                            <input className="form-control" type="color" id="inputSecondaryButtonTextColor"
                            value={this.state.secondary_button_text_color} onChange={this.onChangeSecondaryButtonTextColor} />
                        </div>
                    </div>

                    <div className="form-group col-md-4 text-center">
                        <h6>Permitir Nuevos Usuarios</h6>
                        <YesNoSwitch key="newusers" onChange={this.onChangeAllowNewUsers} on={this.state.allow_new_users === 1} />
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">Confirmar</button>
                    </div>
                </form>
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.setState({openStores: true})}>
                    <Alert onClose={() => this.setState({openStores: true})} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }

}

export default WebApp